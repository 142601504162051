import request from "@/utils/request";

export  function save(data){
  return request({
    method: "post",
    url: '/system/company/save',
    data,
  });
}
export  function del(data){
  return request({
    method: "post",
    url: '/system/company/delete',
    data,
  });
}
export  function list(data){
  return request({
    method: "post",
    url: '/system/company/getList',
    data,
  });
}
export  function detail(data){
  return request({
    method: "get",
    url: '/system/company/detail',
    params:data,
  });
}


