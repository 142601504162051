<template>
  <xzd-list-page
    ref="xzdListPage"
    module-name="employee"
    :get-list-func="getList"
    :queryParam="queryParam"
    :show-import-excel-button="false"
    :show-export-excel-button="false"
    can-select>
    <div slot="searchBox">
      <employee-list-search-box v-model="queryParam" @onSearch="xzdListPageReload"/>
    </div>
    <div slot="buttonsBox" slot-scope="{selectedRows}">
      <save-employee-button @xzdListPageReload="xzdListPageReload"/>
      <delete-employee-button :ids="selectedRows.map(v=>v.id)" @xzdListPageReload="xzdListPageReload"/>
    </div>
    <template slot="columns">
      <el-table-column prop="id" :label="$t('common.id')" width="100"/>
      <el-table-column prop="companyName" :label="$t('employee.company')"/>
      <el-table-column prop="account" :label="$t('employee.account')"/>
      <el-table-column prop="password" :label="$t('employee.password')"/>
      <el-table-column prop="name" :label="$t('employee.name')"/>
      <el-table-column prop="realName" :label="$t('employee.realName')"/>
      <el-table-column prop="sort" :label="$t('employee.sort')" width="80px"/>
      <el-table-column :label="$t('common.operate')" width="200" align="center">
        <template slot-scope="scope">
          <save-employee-button :id="scope.row.id" @xzdListPageReload="xzdListPageReload"/>
          <delete-employee-button :ids="[scope.row.id]" @xzdListPageReload="xzdListPageReload"/>
        </template>
      </el-table-column>
    </template>
  </xzd-list-page>
</template>
<script>
import {list as getList} from "@/api/user";
import EmployeeListSearchBox from "./EmployeeListSearchBox";
import SaveEmployeeButton from "../buttons/SaveEmployeeButton";
import DeleteEmployeeButton from "../buttons/DeleteEmployeeButton";
import XzdListPage from "@/components/xzd/listPage/XzdListPage";

export default {
  name: "EmployeeList",
  components: {XzdListPage, DeleteEmployeeButton, SaveEmployeeButton, EmployeeListSearchBox},
  data() {
    return {
      queryParam: {}
    }
  },
  methods: {
    getList(data) {
      return getList({
        ...data,
        employeeList: true
      })
    },
    xzdListPageReload(p = true) {
      this.$refs.xzdListPage.reload(p)
    }
  }
}
</script>